@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto.ttf');
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  scrollbar-width: none;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #1B1B1B;
  background-color: #f5f5f5;
  overflow-y: visible;
  overflow-x: hidden;
  -ms-overflow-style: none;
}

.root::-webkit-scrollbar {
  display: none;
}

@media (any-hover: hover) {
  * {
    transition: background-color 300ms,
      color 300ms,
      fill 300ms,
      top 100ms ease-in-out,
      bottom 100ms ease-in-out,
      left 100ms ease-in-out,
      right 100ms ease-in-out
  }
}