.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  /* overflow-x: hidden;
  overflow-y: auto; */
}

.notification {
  box-sizing: border-box;
  padding: 16px 32px;
  border-radius: 4px;
  color: #1b1b1b;
  box-shadow: 0px 4px 8px #0000001f, 0px 6px 12px #0000001f,
    0px 1px 16px #0000001f;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.3s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #FFFFFF;
}
.notification-info:hover {
  color: #5578F6;
}
.notification-info:before {
  /* content: ""; */
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {}

.notification-error {
  background-color: #fff;
}
.notification-error h4 {
  color: #EB5757;
}

.notification-error:before {}